.footer{
  /* border-top: 1px solid #ccc; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  padding-top: 100px;
  /* border-top: 4px solid black; */
  /* padding-bottom: 50px; */
  /* box-shadow:rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.FooterMain{
  width: 85%;
  margin: auto;


}

.FooterMenu{
  display: flex;

  align-items: center;
  justify-content: space-between;
}

.footerMenuContainer{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footerMenuContainerSocialIcon{

  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom:15px;
}

.footerMenuContainer > p{
  margin-top: 5px;
  margin-bottom: 5px;
  color:#3A3B3C;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.footerMenuContainer > a{
  margin-top: 5px;
  margin-bottom: 5px;
  color:#3A3B3C;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.footerMenuContainer > h2{
  margin-bottom: 15px;
}


.FooterBottom{
 display: flex;
 width: 80%;
 justify-content: space-between;
 margin: 60px auto auto auto;
 text-align: center;
 align-items: center;
}
.FooterBottom>p{
  font-size: 14px;
  line-height: 25px;
  
}

.socialMediaFooter{
  display: flex;
  width: 10%;
  justify-content: space-between;
}

.FooterEnd{
  
  margin-top: 70px;
  width: 100%;
  background-color: black;
  padding-bottom: 10px;
  padding-top: 15px;
}


.footerCopyright{
  margin-right: 5px;
}

.footerEndAlign{
  width:80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.Textnone{
  text-decoration: none;
}


.optionalRow{
  display:flex;
  align-items:center;
  width:50%;
  justify-content: space-between;
}


@media only screen and (max-width: 750px) {

  .optionalRow{
    display:flex;
    align-items:center;
    width:100%;
    justify-content: space-between;
  }
  .FooterMenu{
 flex-direction: column;
 /* align-items: center; */
 justify-content: flex-start;
 align-items: flex-start;
  }

  .footerMenuContainer{
    align-items: flex-start;
    margin-bottom: 15px;
  }
  
  .FooterBottom{
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: initial;
    margin-top: 30px;
    width: 100%;
   }

   .FooterBottom > p{
    text-align: justify;
    font-size: 12px;
   }

   .footer{
    padding-top: 30px;
   }

   .footerMenuContainer > a{
    margin-top: 10px;
    margin-bottom: 10px;
   }

   .footerMenuContainer > p{
    margin-top: 10px;
    margin-bottom: 10px;
  }

   .socialMediaFooter{
  
    width: 20%;
  }


  .footerEndAlign{
   
    display: flex;
    flex-direction: column-reverse;
  
  }

  .socialMediaFooter{
    margin-bottom: 10px;
    width: 35%;
  }
  

  .footerCopyright{
    font-size: 14px;
    
  }


  .Textnone{
    font-size: 12px;
  }
   
  

}


