.Container {
  margin: 0 auto;
  padding: 0;
  text-transform: uppercase;
  width: 90%;
  text-align: center;
  font-size: 3.5rem;
}

.Container1 {
  margin: 24px auto 0;
  padding: 0;
  width: 80%;
  text-align: center;
  font-size: 1.75rem;
}

.Container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.15);
  color: white;
}

.HeroButton {

background: rgba(255, 255, 255, 0);
border-radius: 10px;;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(20px);
-webkit-backdrop-filter: blur(20px);
  width: 150px;
  /* padding: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  border: none;
  
  /* color:white; */
}

.heroTypoChild{

width:60%;
/* padding: 20px; */
}


.heriMainTypo{
  font-size:60px;;

}

.heroMiniTypo{
  width:70%;
  font-size:20px;
  font-weight: 500;
}

.typoMainParentBox{
  display:flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 90%;
}


@media only screen and (max-width: 700px) {

  .typoMainParentBox{
align-items: start;
margin-bottom: 200px;

}

.heroTypoChild{

  width:80%;
  /* padding: 20px; */
  }
    

  .heriMainTypo{
    font-size:30px;;
    text-align: start;
  
  }
  
  .heroMiniTypo{
    width:100%;
    font-size:14px;
    text-align:start;
  }

  
  
  
}

@media only screen and (max-width: 400px) {

    

  .heriMainTypo{
   font-size: 50px;
  
  }
  
  .heroMiniTypo{
    font-size: 19px;
  }

  
  
  
}