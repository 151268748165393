.ItemMain {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.itemSide {
  width: 90%;
  margin-top: 50px;
  margin-bottom: 120px;
}

.catHead {
  text-align: center;
}





@media only screen and (max-width: 600px) {
    .ItemMain {
      flex-direction: column;
    }


    .itemSideChild {
        display: grid;
        grid-template-columns: 90%;
        padding: 20px;
        justify-content: space-around;
        grid-row-gap: 50px;
      }
  
  
    .categorySide {
      width: 80%;
      margin: auto;
      margin-top: 150px;
      height: 27vh;
      margin-bottom: 2vh;
    }
  
    .categorySideChild{
      display: grid;
  
  grid-template-columns:  30% 30% ;
  padding: 10px;
  }
  .itemSide {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 120px;
    }

    .categorise{
        font-size: 12px;
    }


    
  }