.homeRoot{
  background-color: #f5f5f5;
  padding-bottom: 100px;
}

.lines{
    /* background-image: url("./content/lines.svg"); */
  margin-top: 100px;
    width: 100%;
  }
  
  .lineImg{
    background-repeat: no-repeat;
    object-fit: cover; 
    width: 100%;
  }