.WhyUs{
    width:80%;
    margin: 120px auto 120px auto;
}


.whyText{
    text-align: center;
    margin-bottom: 50px;
}

.whycircle{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #7a530c;
    margin: auto;
}

.Whyicons{
    margin-top: 35%;
    margin-left: 35%;
    color: white;

}


.whyP{
    text-align: center;
    width: 70%;
    margin: 25px auto auto auto;
    line-height: 20px;
    font-size: 18px;
    color: #5A5A5A;
    font-weight: 600;

}


@media only  screen and (max-width:600px){

    .WhyUs{
        width:90%;
    }
    .whyP{
       
        width: 80%;
        margin: 10px auto auto auto;
        line-height: 20px;
        font-size: 12px;
  
    
    }
}
/* .WhyBox{
    display: flex;
    flex-direction: ;
} */