.feedbackMain {
  width: 100%;
  margin-top: 200px;
}

.feedbackSemiMain {
  width: 100%;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 200px;
 
}


.feedbackBoxContainer{
}

.feedback {
  /* padding: 5vw; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(60px);
  -webkit-backdrop-filter: blur(20px);
  
}

.feedbackTypo {
  font-size: 18px;
  /* margin: 0 5px 24px; */
  padding: 10px;
  /* color:"black"; */
  color:lightgray;
  font-weight: 600;
  line-height: 23px;
  margin: 0 5px 24px;
  font-family: "Libre Baskerville", serif;
}

.quote {
  /* background: #f9f9f9; */
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";

}

.quote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

@media only screen and (max-width: 800px) {
  .feedbackTypo {
    font-size: 13px;
    line-height: 20px;
  }
  .feedbackSemiMain {
    padding-top: 20px;
    padding-bottom: 20px;
   
  }
}
