.brandsMain{
    width: 90%;
    margin-top: 120px;
    margin: auto;
}

.brandsWeCarryContainer{
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-gap: 20px;
    grid-row-gap: 40px;
    margin-top: 50px;
}


.brandsWeCarryBoxes{
    border-bottom-left-radius: 80px;
    /* 0px 7px 29px 0px */
    /* box-shadow:  rgb(150, 75, 0) 0px 7px 29px 0p; */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.3));
    /* box-shadow: rgba(100, 100, 111, 0.2) ; */
    padding: 5px;
    margin: auto;
    /* border:3px solid #63462D; */
    /* border: 5px; */
}


.brandText{
    text-align: center;
    margin-bottom: 0px;
            /* color:white;
        background: linear-gradient( 257deg, rgba(122, 83, 12, 1) 0%, rgba(0, 0, 0, 1) 100% ); */
    
}

@media only  screen and (max-width:1200px){
    .brandsWeCarryContainer{
        grid-template-columns: 33% 33% 33%;
    }
    
}

@media only  screen and (max-width:800px){
    .brandsWeCarryContainer{
        grid-template-columns: 50% 50%;
        justify-content: center;
        grid-row-gap: 0px;
        
    }

    .brandsMain{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .brandText{
        border: 1px solid ;
        padding: 10px;
        width: 90%;
        color:white;
        background: linear-gradient( 257deg, rgba(122, 83, 12, 1) 0%, rgba(0, 0, 0, 1) 100% );
    }

    
}


@media only  screen and (max-width:470px){
    .brandsMain{
        width: 95%;
    }
    .brandsWeCarryBoxes{
        padding: 12px;
  
    }

    .brandsWeCarryContainer{
        grid-gap: 10px;
        grid-row-gap: 40px;
       }
    
    
}


/* @media only  screen and (max-width:550px){
    .brandsWeCarryContainer{
        grid-template-columns: 45% 45%;
    }
    
} */