.kitchenModal{
    width: 80%;
    margin: auto;
    margin-top: 40px;
}

.gridKitchenContainer{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-row-gap: 60px;
    grid-column-gap: 50px;
}

.gridKitchenBox{
    width:100%;
    display:flex;
    flex-direction: column;
    height:320px;
    justify-content: space-between;
    
}

.kitchenImage{
    object-fit: contain;
}

.fullImageCancel{
    position: absolute;
    z-index: 6;
    right: 30px;
    top:20px;
}
.KitchenHeadingContainer{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
}

.KitchenHeadingContainer >h2 >p {
    text-align: center;
}

.KitchenHeadingContainer >p{
    margin-top: 5px;
    line-height: 20px;
    width: 90%;
}

.featureKitchen{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 100px;
}

.kitchenFeatureText > p{
    margin-top: 5px;
    margin-bottom: 10px;
    line-height: 25px;
    font-size: 14px;
    width: 90%;
}

.kitchenFeatureText{
    margin-bottom: 30px;
}

.headingFeature{
    padding: 2px 5px;
    /* background-color: #cccccc; */
    width: fit-content;
    border-radius: 1px;
}



.fullImageView{
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 100px;
    z-index: 1101;
    left: 0;
    right: 0;
    top:0px;
    margin: auto;
    background-color:black;
    /* filter:brightness(100%) */

}



@media only screen and (max-width: 1300px) {
    .gridKitchenContainer{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-row-gap: 60px;
        grid-column-gap: 40px;
    }
    

}




@media only screen and (max-width: 750px) {
    .gridKitchenContainer{
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 60px;
        grid-column-gap: 0px;
    }

    .KitchenHeadingContainer{
        align-items: flex-start;
        margin-bottom: 20px;
        width: 100%;
    }

    .KitchenHeadingContainer > p {
        width: 100%;
        text-align: left;
        margin-top: 20px;
    }

    .kitchenModal{

        width: 90%;
    }

    

}

