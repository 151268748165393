.itemsitemSideChild {
    display: grid;
    grid-template-columns: 30% 30% 30%;
  
    padding: 20px;
  
    justify-content: space-around;
    grid-row-gap: 50px;
  }


  .itemsitemBox {
    display: flex !important;
    flex-direction: column !important;
    justify-content:space-between  !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    height: 350px !important ;
    background-color: white !important;
    border-radius: 15px !important;
    cursor: pointer !important;
  }

  .itemsitemImageCont {
    height: 60%;
  }

  .productsPageSavings{
    width: 80px;
    height: 25px;
    position: absolute;
    z-index: 2;
    background: linear-gradient(
      257deg,
      rgba(122, 83, 12, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 30px;
    color: white;
    letter-spacing: 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  }
  
  .productsPageSavings > p{
    font-size: 12px;
  }
  .itemsimgBox {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

  }

  .itemsitemDetail {
    display: flex;
    flex-direction: column;
    height: inherit !important;
    padding: 1rem;
    justify-content: space-around;
    height:40% !important;

  }
  
  .itemsdetailChild1 {
    display: flex;
    
    width: 100%;
    /* height: 80%; */
    /* padding-left: 5px; */
   
  }
  
  .itemsdetailChild1 > p {
    font-size: 14px;
    font-weight: 600;
  }

  .itemsitemDetail > h3{
    text-decoration: none;
    color: black;

    
  }

  .itemsdetailChild2 {
    display: flex;

    /* width: 90%;
    margin: auto; */
    /* height: 100%; */
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  
  .itemsarticle {
    background-color: rgba(122, 83, 12, 1);
    width: 50%;
    text-align: center;
    padding: 3px;
    color: white;
    margin-left: 45%;
    margin-top: 5px;
    border-radius: 4px;
  }
  
  /* .detailChild2 > p{
      font-size: 16px;
  } */
  
  .itemsdetailPrice {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
 
  
  .itemspriceText {
    font-size: 20px;
    text-align: right;
    /* margin-right: 8px; */
    font-weight: bold;

    color: #097969
  }


  .ItemIncludes{
    display:flex;
    align-items: flex-start;
    width:90%;
  margin: auto;  
  margin-bottom: 10px;
}


.itemIncludesList{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  /* margin-left: 10px; */

}

.itemIncludesBox{
  background-color:#f5f5f5;
  padding: 5px;
  /* border: 1px solid black; */
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.itemIncludeTittle{
  font-size: 14px;
}




  @media only screen and (max-width: 1100px) {
    .itemsitemSideChild {
      display: grid;
      grid-template-columns: 45% 45%;
      padding: 20px;
      justify-content: space-around;
      grid-row-gap: 50px;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .itemsItemMain {
      flex-direction: column;
    }
  
  
    .itemscategorySide {
      width: 80%;
      margin: auto;
      margin-top: 150px;
      /* margin-left: 50px; */
      height: 20vh;
      margin-bottom: 2vh;
      /* border: 1px solid black; */
      /* border-top: 2px solid black; */
    }
  
    .itemscategorySideChild{
      display: grid;
  
  grid-template-columns: 30% 30% 30% ;
  padding: 10px;
  }
  .itemsitemSide {
      width: 100%;
      /* border: 1px solid black; */
      margin-top: 50px;
      margin-bottom: 120px;
    }
    
  }
  
  
  @media only screen and (max-width: 600px) {
      .itemsItemMain {
        flex-direction: column;
      }
  
      .itemsitemBox {
        height: 400px !important;
      }
    
      .itemspriceText {
        font-size: 16px;
      
      }
    
  
      .itemsitemSideChild {
          display: grid;
          grid-template-columns: 90%;
          padding: 20px;
          justify-content: space-around;
          grid-row-gap: 50px;
        }
    
    
      .itemscategorySide {
        width: 80%;
        margin: auto;
        margin-top: 150px;
        /* margin-left: 50px; */
        height: 27vh;
        margin-bottom: 2vh;
        /* border: 1px solid black; */
        /* border-top: 2px solid black; */
      }
    
      .itemscategorySideChild{
        display: grid;
    
    grid-template-columns:  30% 30% ;
    padding: 10px;
    }
    .itemsitemSide {
        width: 100%;
        /* border: 1px solid black; */
        margin-top: 50px;
        margin-bottom: 120px;
      }
  
      .itemscategorise{
          font-size: 12px;
      }
  
      .itemsarticle{
        font-size: 12px;
        margin-top: 10px;
  
      }
  
  
      .itemsdetailPrice {
        font-size: 16px;
        /* margin-bottom: 4px; */
      }
      
      .itemsdetailPrice > p {
        /* margin-bottom: 8px; */
        font-size: 14px;
      }
  
      .itemsdetailChild1{
        padding-left: 8px;
  
      }
  
      .itemsdetailChild1 > h3{
        font-size: 15px;
      }
      
    }