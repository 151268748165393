.timeLineContainer{
    margin-top: 40px;
    width: 100%;
    margin-bottom: 80px;
    position: relative

   
}
.timelineMain{
    width: 90%;
    display: flex;
    margin: auto;
    flex-direction: column;
    gap:40px;
    /* gap:20px; */
    /* justify-content: space-between; */
    /* padding: 25px; */

}

.TimelinneBoxesSection{
    display:flex;
    width:100%;
    position: relative;
    z-index: 2;

}

.TimelinneBoxesSectionEnd{
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    

}

.TimelineBox{
    display:flex;
    flex-direction:column;
    width:60%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #EFE0BB;
    padding: 50px;
    border-radius: 0.5rem;
}


.TimelineBox > h2 {
    margin-bottom: 5px;
}
.TimelineBox > p{
    /* text-align:center; */
    margin-top: 5px;
    font-size: 17px;
    line-height: 24px;
    text-align: justify;
}



@media only screen and (max-width: 780px) {
    .TimelineBox{
        width:100%;
        padding: 20px 30px;

    }
    .TimelineBox > p{
        font-size: 14px;
        line-height: 19px;
    }
    
    
    
  }
  

/* .TimeLineBackground{
    height: 85vh;
    width: 100%;
    position: absolute;
    bottom: 75px;

    background: linear-gradient(
        257deg,
        rgba(122, 83, 12, 1) 0%,
        rgba(0, 0, 0, 1) 100%
      ); */

          /* background-image: url(../../content/TimelineFurniture.jpg);
    bottom: 75px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
    /* background: #913a00; */







/* background: -webkit-linear-gradient(to left bottom, #913a00, #b58d6d, #943d03);
background: linear-gradient(to left bottom, #913a00, #b58d6d, #943d03); */

/* } */











.timeLineBox{

border-radius: 26rem;
width: 32%;
height: 350px; 

}





.timelineImg{
    height:100%;
    width:100%;
    object-fit: cover;
    border-radius: 18px;
    filter: brightness(55%);
}

.timelineImg:hover{
    filter:brightness(70%);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    
}






.timelineTypo{
    position: relative;
    width:80%;
    margin:auto;
    bottom:210px;
    display: flex;
    flex-direction: column;
    gap:10px
    
}


.headingh2{
    text-align: center;
    color:white;
}


.timelinePara{
    text-align: center;
    color:white
}

@media only  screen and (max-width:860px){
    .headingh2{
       font-size: 22px;    /* font-size: 20px; */
    }
    .timelinePara{
        font-size: 15px;
    }
}

@media only  screen and (max-width:600px){
    .timelineMain{
     
        flex-direction: column;
        gap:25px
     
    
    }

    .timeLineBox{

        border-radius: 26rem;
        width: 100%;
        /* height: 100%;  */
        
        }
        
        .timelineTypo{
            position: relative;
            width:80%;
            margin:auto;
            /* bottom:55vw; */
            display: flex;
            flex-direction: column;
            gap:10px
            
        }

}