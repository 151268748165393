.topProductsMain{
    width:100%;
    margin-top: 80px;
}

.topProductsSemiMain{
    width:90%;
    margin:auto;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    /* padding: 2vw; */
    
}

.productsBox{
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: 350px;
    background: 	#F8F8FF;
    /* margin-left:2vw; */
    margin: 5vw auto 5vw auto;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 3px;
     -webkit-transition: all 0.4s ease; 
    -moz-transition: all 0.4s ease; 
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease; 
    transition: all 0.4s ease; 

   
}


.productsBox:hover {
   
    -webkit-transform:scale(1.1); 
    -moz-transform:scale(1.1); /* Firefox */
    -ms-transform:scale(1.1); /* IE 9 */
    -o-transform:scale(1.1); /* Opera */
     transform:scale(1.1);
    
     
}



@media only  screen and (max-width:600px){
    .productsBox{
        width: 310px;
        /* margin-left: 6vw; */
        transition: none !important;
        /* box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
    }

    .productsBox:hover {
   
    
         transform:none;
        
         
    }
   
    
}