.productDetail {
  width: 90%;
  margin: 50px auto 150px auto;
  display: flex;
  justify-content: space-between;
}

.productDetailSlider {
  width: 68%;
}

.prdocutAboutHeader {
  display: flex;
  justify-content: space-between;
}

.vogueWoodArticle {
  background: linear-gradient(
    257deg,
    rgba(122, 83, 12, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  color: white;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
  align-items: center;
}

.alertPopUp {
  text-align: justify;
  margin-left: 5px;
}

.alertPopUpBullet {
  text-align: justify;
  margin-top: 5px;
}
.ProductAbout {
  width: 30%;
  background-color: #f5f5f5;
  padding: 30px 10px 50px 10px;
  border-radius: 10px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.prdocutAboutHeader {
  align-items: center;
}

.productAboutName {
  font-size: 25px;
  color: #373737;
  font-weight: bolder;
}

.productBioCategory {
  font-size: 20px;
  color: black;
  font-weight: bolder;
}

.Productsize {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  align-items: center;
}

.Productsize > span {
  font-size: 20px;
  color: black;
  font-weight: bolder;
  margin-right: 5px;
}

.includesBoxes {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
background-color: white;
}

.includes {
  font-size: 20px;
  color: black;
  font-weight: bolder;
  margin-right: 5px;
}

.productPriceCategory {
  font-size: 25px;
  color: #373737;
  font-weight: bolder;
  color: #097969;
}
.productBioDivider {
  /* width: 50%; */
  padding-bottom: 10px;
  padding-top: 5px;
}

.productPrice {
  display: flex;
  flex-direction: column;
  /* width: 35%; */
  justify-content: space-between;
  margin-top: 10px;
}

.priceText {
  font-weight: 500;
  font-size: 25px;
}

.cutPrice {
  font-size: 18px;
  color: #4c0000;
}

.productDes {
  font-size: 18px;
  line-height: 22px;
  align-items: center;
}

.productDes > span {
  font-size: 20px;
  font-weight: bolder;
  color: black;
  margin-right: 5px;
}

.discountedPriceContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.outOfStock {
  width: 200px;
  height: 45px;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    257deg,
    rgba(122, 83, 12, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 30px;
  color: white;
  letter-spacing: 2px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.outOfStock > p {
  font-size: 14px;
}

.productArticleTag {
  display: flex;
  width: 35%;
  margin-top: 10px;
}

@media only screen and (max-width: 1000px) {
  .productDes {
    font-size: 13px;
  }

  .productHeading {
    font-size: 18px;
  }

  .priceText {
    font-size: 20px;
  }
}

.productSuggestedSection {
  width: 90%;
  margin: auto;
  padding-bottom: 100px;
}
@media only screen and (max-width: 700px) {
  .productDetail {
    margin: 20px auto 50px auto;

    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .alertPopUp {
    font-size: 12px;
  }

  .alertPopUpBullet {
    font-size: 12px;
  }

  .productDetailSlider {
    width: 100%;
  }

  .ProductAbout {
    margin: auto;
    width: 100%;
    margin-top: 0px;
    padding: 15px;
  }
  .productButton {
    padding: 18px;
    width: 100%;
    margin-top: 25px;
  }
  .productDes {
    font-size: 16px;
    line-height: 21px;
  }

  .priceText {
    font-size: 22px;
    font-weight: bold;
    margin-top: 8px;
  }

  .Productsize > p {
    font-size: 16px;
  }

  .productBioCategory {
    font-size: 18px;
  }

  .productAboutName {
    font-size: 20px;
  }

  .Productsize > span {
    font-size: 18px;
  }

  .productDes > span {
    font-size: 18px;
  }

  .outOfStock {
    width: 150px;
    height: 40px;
    position: absolute;
    z-index: 2;
    background: linear-gradient(
      257deg,
      rgba(122, 83, 12, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 30px;
    color: white;
    letter-spacing: 2px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .outOfStock > p {
    /* font-size: 12px; */
  }

  .productSuggestedSection {
    width: 100%;
  }
}
