.formContainer{
    width: 90%;
    margin: auto;
    padding: 35px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 120px;
    /* margin-bottom: 40px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 25px;
    border:8px solid #63462D;
   

}

.formcont{
    display: flex;
    flex-direction: column;
    width: 50%;
    

}

.formBox{
    display: flex;
    flex-direction: column;

    background-color: rgba(0, 0, 0, 0.89);
	border-radius:16px;
    padding: 35px;
}

.formInput{
    border:0;
  border-bottom:1px solid #555;  
  background:transparent;
  width:70%;
  margin: auto;
  padding:8px 0 5px 0;
  font-size:16px;
  color:white;
  margin-bottom: 15px;
  font-size: 12px;

}


.formH1{
    text-align: center;
    margin-bottom:15px;

}
.formInput:focus {
    border:none;	
 outline:none;
 border-bottom:1px solid #63462D;	
}



.formLabel{
    /* position:absolute; */
	/* top:0px;
	left:0px; */
	font-size:16px;
	color:white;
    text-align: start;
    width: 70%;
    margin: auto;	
    pointer-event:none;
	transition: all 0.5s ease-in-out;
}

.formBut{
    width: 100%;
    margin: auto;
    background: #63462D;
    border-radius: 15px;
}

.formBut:hover{
    background-color:transparent;
}

@media only screen and (max-width: 1300px) {

    .formcont{
        width: 40%;
    }
}


@media only screen and (max-width: 1135px) {
    .formContainer{
        flex-direction: column;
        width: 100%;
        border: none;
        box-shadow: none;
        padding: 0px;
    }
    .formBox{
        width: 100%;
        
    }

    .formcont{
        width: 70%;
        
    }

}

@media only screen and (max-width: 800px) {
    .formcont{
        width: 90%;
    }

    .formInput{
    
      width:95%;
      font-size: 10px;
    
    }

    .formImage{
        display: none;
    }


    .formLabel{
        /* position:absolute; */
        /* top:0px;
        left:0px; */
        font-size:12px;
        color:white;
        text-align: start;
        width: 95%;
        margin: auto;	
        pointer-event:none;
        transition: all 0.5s ease-in-out;
    }

    .formBut{
        /* width: 70%; */
        font-size: 12px;
        width: 100%;
    }

 

    
}

@media only screen and (max-width: 800px) {
.formH1{
    font-size: 25px;
}
}