.MainAbout{
    width: 80%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 200px;
}

.ParagraphAboutContainer{
    margin-top: 100px;
    margin-bottom: 50px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}

.headingAbout{
    padding: 2px 5px;
    /* background-color: #cccccc; */
    width: fit-content;
    border-radius: 1px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 22px;
}

.ParaAbout{
    line-height: 25px;
    font-size: 16px;
    width: 60%;
    text-align: justify;

}


.AboutUsHeaderLine{
    text-align: center;
    margin-bottom:20px;
}

@media only screen and (max-width: 700px) {

    .headingAbout{
        font-size: 16px;
    }
    .ParaAbout{
        font-size: 12px;
        width: 90%;
    }

}
