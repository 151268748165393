.svg{
    position: fixed;
    z-index: 5;
    bottom: 3vw;
    width: 100%;
    right:0;
    display: flex;
    justify-content: flex-end;
} 


@media only  screen and (max-width:500px){
        
    .svg{
        bottom: 10vw;
    }
}