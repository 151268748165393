.mainSection{
    width: 100%;
    margin-top: 80px;
    scroll-behavior: smooth;
    padding-top: 2vw;
    padding-bottom: 6vw;
    /* background-image: url("https://images.unsplash.com/photo-1422246654994-34520d5a0340?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"); */
}

.semiMain{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* margin: auto; */
    width: 90%;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  */
}

.typoSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    padding: 2vw;
    text-align: center;
}

.para{
    color: white; 
    font-size: 20px; 
    font-family: "Libre Baskerville", serif; 
    /* line-height: 45px;  */
    text-align: center; 
    /* text-shadow: 0 1px 1px #fff;  */
    padding-top: 20px;
    /* padding-bottom: 10px;  */
}



/* @media only  screen and (max-width:1100px){
    .typoSection{
        padding: 2vw;
    }
} */

@media only  screen and (max-width:1000px){
        
    .semiMain{
        flex-direction: column;
    }
    .typoSection{
        margin-top: 0vw;
        text-align: center;
        width: 100%;
        margin-bottom: 5vw;
        padding: 3vw;
    }
    .para{
        font-size: 15px; 
        font-family: "Libre Baskerville", serif; 
        /* line-height: 30px;  */
        text-align: center; 
        padding-top: 20px; 
    }
}

@media only  screen and (max-width:800px){

    .semiMain{
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* margin: auto; */
        width: 90%;
        margin: auto;
        /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  */
    }


    .mainSection{
        margin-top: 0px;
        width: 100%;
        margin-top: 0px;
    
        padding-top: 0vw;
        padding-bottom: 0vw;
    }

        .para{
            color: white; 
            font-size: 15px; 
            font-weight: 100;
            font-family: "Libre Baskerville", serif; 
            /* 
              */
            text-align: center; 
            /* text-shadow: 0 1px 1px #fff;  */
            padding-top: 0px; 
        }
        .typoSection{
            margin-top: 0vw;
            text-align: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 5vw;
            padding: 3vw;
        }
    
}

@media only  screen and (max-width:800px){
    .para{
   
        font-size: 12px; 
       
    }
}
