.ValuemainComp{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;
    margin-bottom: 120px;
    
}

.valueInnerMain{
    width: 70%;
    padding: 25px;
    margin: auto;
    border-bottom: 4px solid rgba(122,83,12,1);
    
}


.valueText{
    font-size: 50px;
    background: -webkit-linear-gradient(black, rgba(122,83,12,1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 5px;
    
  
}

.valueFont{

    font-size: 22px;
    text-align: center;
    line-height: 30px;
    
}

.valuelineCont{
    /* display: grid;
    grid-template-columns: 30% 30% 30%; */
    display: flex;
    width: 50%;
    margin: auto auto 15px auto;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
}

.valueline{
    border-bottom: 1px solid rgba(122,83,12,1);
    width: 20%;
}


.valueh2{
    /* margin-bottom: 20px; */
}



@media only screen and (max-width: 700px) {

    .valueText{
        font-size: 40px;
        background: -webkit-linear-gradient(black, rgba(122,83,12,1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
      
    }
    
    .valueFont{
    
        font-size: 18px;
        text-align: center;
        line-height: 30px;
        
    }

    .valuelineCont{
        /* display: grid;
        grid-template-columns: 30% 30% 30%; */
        display: flex;
        width: 70%;
        margin: auto;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
    }
    
    .valueline{
        border-bottom: 1px solid rgba(122,83,12,1);
        width: 20%;
    }

    .valueh2{
        font-size: 10px;
    }
    
    
}

@media only screen and (max-width: 500px) {

    .valueInnerMain{
        width: 80%;
        padding: 15px;
        margin: auto;
        border-bottom: 4px solid rgba(122,83,12,1);
        
    }
    

    .valueText{
        font-size: 20px;
        background: -webkit-linear-gradient(black, rgba(122,83,12,1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
      
    }
    
    .valueFont{
    
        font-size: 14px;
        text-align: center;
        line-height: 22px;
        
    }
}


/* background: linear-gradient(90deg, rgba(175,130,49,1) 0%,  100%); */