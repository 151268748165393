.container {
  /* position: fixed; */
  z-index: 4;
  left: 0;
  top: 0vw;
  /* background-color: white; */
  right: 0;
  position:sticky;
  bottom: 0;
  width: 100%;
  height: 8%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.19);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.2px);
  -webkit-backdrop-filter: blur(13.2px);
  border-bottom: 2px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.HeadLines{
  background-color:black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}


.navbarSliderDiv{
  width:100%;
  margin: auto;
}

.HeadLines >P{
  color: white;
  margin: auto;
  display: flex;
justify-content: center;
align-items: center;
  width: 50%;
  text-align: center;
  height: 100%;
}


.naviMenu{
  /* margin-top: 2px; */
  text-align: center;
  /* margin-left: 20px;
  margin-right: 20px; */
  text-decoration: none;
  color: black;
  font-weight: 500;
  display: flex;
  align-items: center;


}

.semiContainer {
  width: 100%;
  background-color: white;
  /* margin: auto; */
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: space-between;
  height: 115px;
  align-items: center;
  /* padding-bottom: 25px; */
  /* padding-left: 25px;
  padding-right: 25px; */
}

.menu {
  display: flex;
  /* width: 45%; */
  width: 70%;
  margin: auto;
}

.menuOptions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px; */
  /* justify-content: space-around;
  align-items: center; */
}
.icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 13%;
}

.a {
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* font-weight: 600; */
  font-size: 16px;
  display: flex;
  align-items: center;
}

.a:hover {
  color: gray;
}

.hamburgerContainer {
  position: sticky;
  z-index: 1001;
  top: 0px;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.19); 
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.2px);
  -webkit-backdrop-filter: blur(13.2px); */
  background-color: white;
}

.hamburgerMenu {

 
  background: rgba(255, 255, 255, 0.19);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.2px);
  -webkit-backdrop-filter: blur(13.2px);
}

.logoImg {
  /* height: 18vw; */
  position: absolute;
  left: 0vw;
  margin-top: 5px;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}



.drawerB {
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

}

.footerMenuContainerSocialIcon {
  display:flex;
  align-items: center;
}

.drawerBox {
  /* From https://css.glass */
  margin-top: 5rem;
  padding: 3rem;
}

.menuLogoContianer{
  display:flex;
  flex-direction: column;
  height: 75vh;

}

@media only screen and (max-width: 1050px) {
  .menu {
    width: 60%;
  }
}


@media only screen and (max-width: 600px) {
  .HeadLines{
    /* background-color:black;
    width: 100%; */
    height: 30px;

  }

  .HeadLines >P{

    width: 80%;
    font-size: 13px;

  }
  

}
