.productButton {
  /* background-color: rgba(122, 83, 12, 1); */
  background: linear-gradient(
    257deg,
    rgba(122, 83, 12, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(122, 83, 12, 1);
  margin-top: 12px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;

}

@media only screen and (max-width: 700px) {
  .productButton {
    padding: 18px;
    width: 100%;
    margin-top: 25px;
  }
}
